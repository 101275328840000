<template>
  <div id="AddUser" class="row mx-0">
    <div class="row mx-0">
      <div id="header" class="col-sm-12 col-md-12 color-bg-4">
        <Header :name="user.fullname" />
      </div>
      <div class="col-sm-12 col-md-12">
        <div id="content" class="row color-bg-4">
          <div id="" class="">
            <SideMenu :indexsec="13" />
          </div>
          <div id="stn-edituser" class="stn_edituserAdd">

            <form @submit.prevent="
              submit(
                data.opt,
                data.blog_izq.id,
                data.blog_der.id,
                blog_der,
                data.video

              )
              ">
              <div class="div_EProduct">
                <p>EDITAR BANNER</p>
              </div>

              <div class="EPF2_C1 row row-one bannerinputs">



                <div class="row mx-0 row-one">
                  <div class="EPF1_C1">
                    <label class="labelR color-1" for="inputR">Modo Banner</label>
                    <b-dropdown>
                      <template #button-content>
                        <div class="row"> <span class="drop-text">{{ data.opt }}</span></div>
                      </template>

                      <b-dropdown-item @click="changeType('Slider')">
                        Slider
                      </b-dropdown-item>
                      <b-dropdown-item @click="changeType('Blogs')">
                        Blogs
                      </b-dropdown-item>
                      <b-dropdown-item @click="changeType('Video')">
                        Video
                      </b-dropdown-item>

                    </b-dropdown>
                  </div>

                </div>



              </div>

              <div v-if="data.opt == 'Blogs'">
                <hr class="hrcenter mtrow">

                <div class="row mx-0 row-two">
                  <div class="EPF1_C1">
                    <label class="labellz color-1" for="inputlz">Blog izquierda</label>
                    <b-form-input v-model="data.blog_izq.title" disabled id="inputlz" type="text" />
                  </div>
                  <div class="">
                    <label class="labellder color-1" for="inputlder">Blog derecha</label>
                    <b-form-input v-model="data.blog_der.title" disabled id="inputlder" type="text" />
                  </div>





                </div>

                <div class="dflx">
                  <div class="blogsencontrados">
                    <div class="dflx">
                      <div id="buscador" class="fixsearch">
                        <div class="dflx">

                          <i class="fas fa-search"></i>
                          <div class="form-buscador">
                            <input v-model="search_value1" type="search" id="input-search" class="form-control" />
                          </div>
                          <button id="btn-search" type="button" class="btn_searchimg" @click="search1(search_value1)">
                            <img src="../../assets/lupa.png">
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="tblblogs">
                      <p class="hblogs">BLOGS DISPONIBLES:</p>
                      <div v-if="blogs1.length > 0">
                        <div v-for="(blog, key) in blogs1" :key="key">
                          <p class="itblogs" @click="addblog_izq(blog)">{{ blog.title }}</p>
                        </div>
                      </div>
                      <div v-else>
                        <p class="noc">No se encontraron coincidencias</p>
                      </div>

                    </div>


                  </div>
                  <div class="blogsencontrados2">
                    <div class="dflx">
                      <div id="buscador" class="fixsearch">
                        <div class="dflx">

                          <i class="fas fa-search"></i>
                          <div class="form-buscador">
                            <input v-model="search_value2" type="search" id="input-search" class="form-control" />
                          </div>
                          <button id="btn-search" type="button" class="btn_searchimg" @click="search2(search_value2)">
                            <img src="../../assets/lupa.png">
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="tblblogs">
                      <p class="hblogs">BLOGS DISPONIBLES:</p>
                      <div v-if="blogs2.length > 0">
                        <div v-for="(blog, key) in blogs2" :key="key">
                          <p class="itblogs" @click="addblog_der(blog)">{{ blog.title }}</p>
                        </div>
                      </div>
                      <div v-else>
                        <p class="noc">No se encontraron coincidencias</p>
                      </div>

                    </div>


                  </div>



                </div>

              </div>



              <!--{{blogs1}}-->






              <div v-if="data.opt == 'Video'">
                <label class="labelA color-1 mtrow EPF1_C1centrado">Video</label>
                <vue-editor id="editor" class="quill-editor" v-model="data.video" :editorToolbar="toolbar()">
                </vue-editor>

              </div>


              <div v-if="this.section == ''" class="row mx-0 row-one">
                <div class="div-error" v-if="this.status == 'error'">
                  <p class="msg-error">{{ this.msg }}</p>
                </div>

              </div>






              <div class="btn-form">
                <b-btn variant="outline-secondary" class="editar-modal " type="submit">GUARDAR</b-btn>
                <b-modal id="modal-editar" ref="modal-editar" no-close-on-backdrop centered hide-footer hide-header>
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal color-6">El banner se edito correctamente</p>
                  </div>
                  <div class="">
                    <b-button class="btn-modal" @click="agregarOtro()">ACEPTAR</b-button>
                    <b-button89 type="button" aria-label="Close" class="close-modal" @click="salir()">×</b-button89>
                  </div>
                </b-modal>
              </div>
            </form>








            <div v-if="data.opt == 'Slider'">
              <hr class="hrcenter mtrow">


              <!--{{images_gallery}} -->
              <form @submit.prevent="
                agregarImagen(
                  file2,
                  file3,
                  link_slider
                )
                ">
                <div class="row mx-0 row-two">

                  <div class="div-image3 ">
                    <label class="labelImg2 color-1" for="inputImg2">Slider PC</label>
                    <b-form-file v-model="file2" id="inputImg2" type="file" name="inputImg2"
                      :placeholder="'Subir imagen'" />
                  </div>
                  <div class="div-image3 ">
                    <label class="labelImg3 color-1" for="inputImg3">Slider Movil</label>
                    <b-form-file v-model="file3" id="inputImg3" type="file" name="inputImg3"
                      :placeholder="'Subir imagen'" />
                  </div>



                </div>

                <div class="row mx-0 row-two">
                  <div class="EPF1_C1">
                    <label class="labellk color-1" for="inputlk">Link</label>
                    <b-form-input v-model="link_slider" id="inputlk" type="text" />
                  </div>


                </div>


                <div class="btn-form-2">
                  <b-btn variant="outline-secondary" class="editar-modal " type="submit">AGREGAR</b-btn>

                </div>
              </form>




              <div v-if="this.section == 'imagenes'" class="row mx-0 row-one">
                <div class="div-error" v-if="this.status == 'error'">
                  <p class="msg-error">{{ this.msg }}</p>
                </div>

              </div>





              <div class="images_gallery Div_ImagesCenter">
                <div class="row gallery_titles">
                  <div class=" gt1">
                    <p>Visualización</p>
                  </div>
                  <div class=" gt2 heliminar">
                    <p>Eliminar</p>
                  </div>
                </div>

                <div id="banner_imgs" v-if="banner_images != 'No se encontraron coincidencias'">
                  <div v-for="(item, key) in banner_images" class="item_gallery banner_images" :key="key">

                    <div class="row">
                      <div class="arrows">
                        <h2 v-if="key != 0" @click="UpItem(item, key)"> &#9650;</h2>
                        <h2 v-if="key < (banner_images.length - 1)" @click="DownItem(item, key)"> &#9660;</h2>
                      </div>
                      <div class="gt1">

                        <p> <img :src="urlpath.url() + '/get-banner-image/' + item.image" alt=""></p>
                        <p> <img :src="urlpath.url() + '/get-banner-image/' + item.image_mv" alt=""></p>
                      </div>

                      <div class="gt2">
                        <p class="eliminar-gallery" @click="eliminarImagen(item.id)">&#10006;</p>
                      </div>
                    </div>
                    <hr>
                  </div>



                </div>
                <div v-else>
                  <div class="mtrow msg_channels">
                    <p>El slider aun no tiene imagenes.</p>
                  </div>
                </div>


              </div>


            </div>
            <div v-if="data.opt == 'Slider'" class="btn-form-3">
              <b-btn variant="outline-secondary" class="editar-modal " @click="actualizarOrden(banner_images)">ACTUALIZAR
                ORDEN</b-btn>

            </div>


            <b-modal id="modal-editar" ref="modal-editar" no-close-on-backdrop centered hide-footer hide-header>
              <div class="message-modal">
                <img :src="IconSuccess" class="success" /><br />
                <p class="text-modal color-6">El banner se edito correctamente</p>
              </div>
              <div class="">
                <b-button class="btn-modal" @click="agregarOtro()">ACEPTAR</b-button>
                <b-button type="button" aria-label="Close" class="close-modal" @click="salir()">×</b-button>
              </div>
            </b-modal>


          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import SideMenu from '../../components/SideMenu';
import logo from '../../assets/cimarrones/logocima_2.png';
import IconSuccess from "../../assets/Vector.png";
import legrafica from "../../assets/Legrafica.png";
import Header from '../../components/Header';
import { mapActions } from "vuex";

import { VueEditor } from "vue2-editor";
let urlpath = require('../../global/url')
export default {
  name: "EditBanner",
  components: {
    Header,
    SideMenu,
    VueEditor
  },
  data() {
    return {

      status: "",
      msg: "",
      msgimg: "success",
      logo: logo,
      legrafica: legrafica,
      IconSuccess: IconSuccess,
      opt_form: "",
      image_izq: null,
      image_der: null,
      file2: null,
      file3: null,
      link_slider: '',
      urlpath,
      section: '',
      search_value1: '',
      search_value2: '',
      blog_izq: '',
      blog_der: '',
      upd1: '',
      upd2: '',
      blogs1: [],
      blogs2: []
    };
  }, async beforeMount() {
    let token = await this.$store.dispatch("admin/getToken");
    this.$store.dispatch("admin/decode", token);
  },
  updated() {
    if (this.upd1 != this.search_value1) {
      let value = this.search_value1
      let array = this.blogs

      let filter = []

      if (array != 'No se encontraron coincidencias' && array != 'error') {
        for (var i = 0; i < array.length; i++) {
          let titulo = array[i].title;
          if (titulo.includes(value)) {
            filter.push(array[i])
          }
        }
      }
      this.blogs1 = filter
      this.upd1 = this.search_value1
    }

    if (this.upd2 != this.search_value2) {
      let value = this.search_value2
      let array = this.blogs

      let filter = []

      if (array != 'No se encontraron coincidencias' && array != 'error') {
        for (var i = 0; i < array.length; i++) {
          let titulo = array[i].title;
          if (titulo.includes(value)) {
            filter.push(array[i])
          }
        }
      }
      this.blogs2 = filter
      this.upd2 = this.search_value2
    }
  },
  async created() {
    let id = this.$route.params.id;
    let payload = {
      id: id,
      option: "banner",
    };
    this.getInfoById(payload);

    let payload2 = {
      id: id,
      option: "banner_images/banner",
    };
    this.getBannerImagesById(payload2);

    await this.getBlogs('Blog');

    let array = this.$store.getters["blog3/data"]

    let arr1 = []
    let arr2 = []
    if (array != 'No se encontraron coincidencias' && array != 'error') {
      for (const item of array) {
        arr1.push(item)
        arr2.push(item)
      }
    }
    this.blogs1 = arr1
    this.blogs2 = arr2

  },
  computed: {
    data() {
      return this.$store.getters["main/data"];
    },
    blogs() {
      let array = this.$store.getters["blog3/data"]

      let arr1 = []
      let arr2 = []
      if (array != 'No se encontraron coincidencias' && array != 'error') {
        for (const item of array) {
          arr1.push(item)
          arr2.push(item)
        }
      }
      this.blogs1 = arr1
      this.blogs2 = arr2




      return array;
    },
    url() {
      return this.$store.getters["main/baseURL"];
    },
    banner_images() {
      return this.$store.getters["banner_images/data"];
    },
    user() {
      return this.$store.getters['admin/getIdentity'];
    },
  },
  methods: {
    ...mapActions("main", ["getInfoById"]),
    ...mapActions("blog3", ["getBlogs"]),
    ...mapActions("banner_images", ["getBannerImagesById"]),
    ...mapActions("banner_images", ["setData"]),
    wait: function () {
      setTimeout(() => this.$router.go(), 200);
    },

    UpItem: function (item, key) {
      let bimgs = this.banner_images;

      let anterior = key - 1
      let actual = key
      let item_actual = item
      let item_anterior = bimgs[key - 1]

      let array = []

      for (var i = 0; i < bimgs.length; i++) {
        if (i == actual) {
          array.push(item_anterior)
        } else if (i == anterior) {
          array.push(item_actual)
        } else {
          array.push(bimgs[i])
        }
      }

      this.setData(array)
    },
    DownItem: function (item, key) {
      let bimgs = this.banner_images;

      let siguiente = key + 1
      let actual = key
      let item_actual = item
      let item_siguiente = bimgs[key + 1]

      let array = []

      for (var i = 0; i < bimgs.length; i++) {
        if (i == actual) {
          array.push(item_siguiente)
        } else if (i == siguiente) {
          array.push(item_actual)
        } else {
          array.push(bimgs[i])
        }
      }

      this.setData(array)

    },
    search1(value) {


      let array = this.blogs

      let filter = []

      if (array != 'No se encontraron coincidencias' && array != 'error') {
        for (var i = 0; i < array.length; i++) {
          let titulo = array[i].title;
          if (titulo.includes(value)) {
            filter.push(array[i])
          }
        }
      }
      this.blogs1 = filter

      return filter

    },

    search2(value) {


      let array = this.blogs

      let filter = []

      if (array != 'No se encontraron coincidencias' && array != 'error') {
        for (var i = 0; i < array.length; i++) {
          let titulo = array[i].title;
          if (titulo.includes(value)) {
            filter.push(array[i])
          }
        }
      }
      this.blogs2 = filter

      return filter

    },
    showModalError() {
      this.$refs["modal-error"].show();
    },
    eliminarImagen: async function (id) {

      let option = 'banner_images'
      let result = await this.$store.dispatch("main/deleteItem", { option: option, id: id });

      if (result.status == 'error') {
        this.section = 'imagenes'
        this.msg = result.message;
        this.showModalError();
      } else {
        this.section == ''
        this.msg = '';
        this.wait()
      }
    },
    agregarImagen: async function (image, image_mv, link) {
      let id_banner = this.$route.params.id;
      this.status = ''
      this.msg = ''

      if (image == null || image_mv == null) {
        this.section = 'imagenes'
        this.status = 'error'
        this.msg = 'Imagen no subida'
      } else {

        if (image.size > 1000000 || image_mv.size > 1000000) {
          this.status = 'error'
          this.msg = 'Tamaño de imagen no válido, sube imagenes menores a 1 MB.'


        } else {


          var data = new FormData();

          data.append('image', image);
          data.append('image_mv', image_mv);
          data.append('link', link);
          data.append('id_banner', id_banner);
          data.append('_method', 'POST');


          this.status = ''
          this.msg = ''

          let result = await this.$store.dispatch("main/addItem", { option: 'banner_images', item: data });

          if (result.status == 'error') {
            this.section = 'imagenes'
            this.status = 'error'
            this.msg = result.message
          } else { // success
            this.content = ''
            this.showModal()
          }

        }
      }

    },
    actualizarOrden: async function (banner_images) {


      let data = {
        "banner_images": banner_images
      }

      let result = await this.$store.dispatch("main/editItem", { option: 'actualizar_orden', item: data });
      this.showModal()
    },
    submit: async function (opt, blog_izq, blog_der, video) {
      let id = this.$route.params.id;

      let banner = this.data

      this.status = ''
      this.msg = ''


      if (video == '' || video == undefined || video == null) {
        video = banner.video
      }





      id = String(id)

      var data = new FormData();

      data.append('blog_izq', blog_izq);
      data.append('blog_der', blog_der);
      data.append('opt', opt);
      data.append('video', video);
      data.append('id', id)



      data.append('_method', 'PUT');




      let result = await this.$store.dispatch("main/editItem", { option: 'banner', item: data });

      if (result.status == 'error') {
        this.status = 'error'
        this.msg = result.message
      } else { // success
        this.content = ''
        this.showModal()
      }


    },
    addblog_izq: function (item) {
      console.log(item)
      this.data.blog_izq = item

    },
    addblog_der: function (item) {
      console.log(item)
      this.data.blog_der = item

    },
    agregarOtro: function () {
      this.wait();
    },
    salir: function () {
      this.$router.push("/administrador").catch((err) => { });
    },
    showModal() {
      this.$refs['modal-editar'].show()
    },
    toolbar: function () {
      return [
        ['video'],
      ];
    },
    changeType: function (value) {

      this.opt_form = value;
      this.data.opt = value;

    },
    image: function (img) {
      let src = this.url + '/get-banner-image/' + img;
      this.status_img(img)
      return src
    },
    status_img: async function (img) {
      let userimg = ''
      try {
        userimg = await this.$store.dispatch("main/getImage", { image: img, option: 'banner' })
        if (userimg.data.status == 'error') {
          this.msgimg = 'error'
        } else {
          this.msgimg = 'success'
        }
      } catch (err) {
        this.msgimg = 'error'
      }
    },



  },
};
</script>

<style scoped>
.heliminar p {
  width: 10vw !important;
}

.arrows {
  width: 2vw;
  padding-top: 1.3vw;
}

.arrows h2 {
  cursor: pointer;
  color: var(--color-7);
  font-size: 1.6vw;
  transition: all 300ms;
}

.arrows h2:hover {
  cursor: pointer;
  color: var(--color-2);
  font-size: 1.7vw;
}

.banner_images p {
  border-bottom: 0px !important;
  height: 7.5vw !important;
}

.banner_images hr {
  width: 46vw;
}

#banner_imgs .gt1 {
  display: flex !important;

}

#banner_imgs .item_gallery .gt1 p {
  width: 18vw;
}

.mlft {
  margin-left: 17vw !important;
}

.hrcenter {
  margin-left: 10vw;
  margin-bottom: 2vw;
}

.blogsencontrados {
  margin-left: 14.5vw;
}

.blogsencontrados2 {
  margin-left: 1vw;
}

.dflx {
  display: flex;
}

.tblblogs {

  width: 20vw;
  height: 15vw;
  margin-top: 1vw;
  overflow: auto;
  margin-left: 1vw;
}

p.hblogs {
  background: var(--color-1);
  color: white;
  padding: .5vw;
  text-align: center;
  margin-bottom: 0vw;
}

p.itblogs {
  color: var(--color-1);
  text-align: center;
  padding: .3vw;
  overflow: auto;
  cursor: pointer;
  border-bottom: 1px solid var(--color-1);
}

p.noc {
  color: grey;
  text-align: center;
  padding: .3vw;
}

.btn-form-3 button {
  margin-top: 2VW;
  margin-left: 49.48VW;
  margin-bottom: 1vw;
  width: 13.125VW;
  height: 4.444444444444445VW;
  background-color: var(--color-7);
  color: var(--color-4);
  border: 0px;
  border-color: 0vw !important;
  border-radius: 1vw !important;
  font-size: 0.9027777777777777vw;
  font-weight: 800;
  letter-spacing: 0.1388888888888889vw;
}

.btn-form-3 button:hover {
  background: var(--color-1);
}
</style>
